import React from "react"
import tw from "twin.macro"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

// Components
import Layout from "@components/layout"
import Section from "@components/section"
import Container from "@components/container"
import Seo from "@components/seo"
import SucForm from "@components/sucForm"

// Assets
import logo from "@images/logos/xywav-logo.svg"
import standupChampion from "@images/logos/logo-suc.svg"

const BecomeStandupChampionPage = ({ location }) => {
  const RECAPTCHA_3_KEY = '6LdL8csqAAAAAC7PyiXqW3d5cCCshA4nlwgBHXmA'

  return (
    <Layout
      location={location}
      headerType="narcolepsy"
      footerType="narcolepsy"
      isiBgColour="white"
      footerBgColour="white"
      pageBgColour="white-lilac"
    >
      <Seo
        title="Standup Champion Sign Up Form | XYWAV®"
        description="Sign up to become a Standup Champion; share your experience with Xywav® (calcium, magnesium, potassium, and sodium oxybates) and inspire others with narcolepsy. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <section tw="relative pt-16 md:(pt-20)">
        <img
          src={logo}
          alt=""
          width="397"
          tw="hidden lg:(block absolute right-[94px] top-8)"
        />
        <Container>
          <h1 tw="font-ffcocon font-bold text-[22px] leading-[28px] lg:(max-w-[570px] text-[25px] leading-[30px])">
            Share your story, <br tw="sm:hidden lg:block" />
            and you could be&nbsp;a <span tw="sr-only">standup champion</span>
            <img
              src={standupChampion}
              alt=""
              tw="block mt-2.5 max-w-[293px] lg:(max-w-[429px])"
            />
          </h1>
          <p tw="mt-5 text-left pr-6 text-base lg:(max-w-[570px])">
            If you are taking XYWAV and would like to help inspire others with
            narcolepsy, please take a moment to share your story. We may use
            some or all of your story on our website or in other materials.
          </p>
        </Container>
      </section>

      <Section tw="px-2 lg:(px-0 py-[50px])">
        <Container grid addedStyles={tw`bg-white rounded-3xl py-6`}>
          <div tw="col-span-full lg:(col-start-2 col-span-10 py-16)">
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_3_KEY}>
              <SucForm />
            </GoogleReCaptchaProvider>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default BecomeStandupChampionPage
